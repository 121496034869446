import axios from "axios";

export default {
  async getItemNames(id) {
    return await axios
      .get("catalogue-upload/item-name-by-season-sale/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getAuctioneer(id) {
    return await axios
      .get("brokers")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getComments(params) {
    return await axios
      .get(
        `auction-allocation/Comments`,
        { params: params }
        // `auction-allocation/Comments?auction_center=${params.auction_center}&season_sale=${params.season_sale}&auctioneer=${params.auctioneer}&item_name=${params.item_name}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
