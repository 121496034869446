<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      My Comments
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>
              <v-card-text>
                <v-form ref="frmFilter">
                  <v-card-text class="px-0 py-0">
                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label
                          class="
                            text-md text-typo
                            font-weight-bolder
                            ms-1
                            mandatory
                          "
                          ><span class="red--text"><strong>* </strong></span
                          >Auction center</label
                        >

                        <v-autocomplete
                          :items="auctionCenters"
                          item-text="auction_center_name"
                          item-value="id"
                          hide-details="auto"
                          v-model="auctionCenter"
                          @change="resetSeason"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          height="46"
                          :rules="[(v) => !!v || 'Auction center is required']"
                          placeholder="Select auction center"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.auction_center_name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Season</label
                        >

                        <v-autocomplete
                          :items="seasons"
                          item-value="id"
                          hide-details="auto"
                          v-model="season"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          outlined
                          height="46"
                          @change="filterSeasonSale()"
                          single-line
                          :rules="[(v) => !!v || 'Season is required']"
                          placeholder="Select Season"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Sale No</label
                        >

                        <v-autocomplete
                          :items="seasonSales"
                          item-value="id"
                          hide-details="auto"
                          v-model="seasonSale"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          @change="getItemNames"
                          height="46"
                          :rules="[(v) => !!v || 'Sale No is required']"
                          placeholder="Select Sale No"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          >Item Name</label
                        >
                        <v-autocomplete
                          :items="item_names"
                          item-value="item_name"
                          item-text="item_name"
                          v-model="item_name"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          chips
                          multiple
                          height="46"
                          single-line
                          placeholder="Select Item Name"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index === 0"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-white text-caption ls-0">{{
                                item.item_name
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ item_name.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="toggle"
                            >
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    item_name.length > 0
                                      ? 'indigo darken-4'
                                      : ''
                                  "
                                >
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Auctioneer</label
                        >
                        <v-autocomplete
                          :items="auctioneers"
                          item-value="id"
                          item-text="broker_name"
                          v-model="auctioneer"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          chips
                          multiple
                          height="46"
                          single-line
                          placeholder="Select Auctioneer"
                          :rules="[
                            (v) => v.length > 0 || 'Auctioneer is required',
                          ]"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index === 0"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-white text-caption ls-0">{{
                                item.broker_name
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ auctioneer.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="toggle1"
                            >
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    auctioneer.length > 0
                                      ? 'indigo darken-4'
                                      : ''
                                  "
                                >
                                  {{ icon1 }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-card-actions class="card-padding d-flex justify-end">
                  <v-btn
                    @click="cancel"
                    elevation="0"
                    :ripple="false"
                    height="46"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-secondary
                      bg-light
                      py-3
                      px-6
                    "
                    >Cancel</v-btn
                  >

                  <v-btn
                    @click="search"
                    elevation="0"
                    :ripple="false"
                    height="46"
                    dark
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                    "
                    >Search</v-btn
                  >
                </v-card-actions>
              </v-card-text>
            </v-card>
            <v-card class="mt-5" v-show="showTable" id="scroll_1">
              <v-data-table
                v-model="selectedData"
                :headers="headers"
                :items="comments"
                item-key="id"
                fixed-header
                class="table"
                show-select
                :footer-props="{
                  'items-per-page-options': [100, 200, 500, -1],
                }"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Comments</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      @click="download"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-primary
                        bg-success
                        py-5
                        px-5
                        shadow
                      "
                    >
                      <v-icon size="20" class="text-white mr-1">
                        fas fa-cloud-download-alt
                      </v-icon>
                      Download
                    </v-btn>
                  </v-toolbar>
                </template>
              </v-data-table>
              <v-card-actions>
                <v-row>
                  <v-col offset-md="5"
                    ><v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      @click="download"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-primary
                        bg-success
                        py-5
                        px-5
                        shadow
                      "
                    >
                      <v-icon size="20" class="text-white mr-1">
                        fas fa-cloud-download-alt
                      </v-icon>
                      Download
                    </v-btn></v-col
                  >
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import masterApi from "../../../master/master-get-api";
import api from "../api";
export default {
  data() {
    return {
      overlay: false,
      comments: [],
      selectedData: [],
      auctionCenters: [],
      auctionCenter: null,
      seasons: [],
      season: null,
      seasonSales: [],
      seasonSale: null,
      item_names: [],
      item_name: [],
      auctioneers: [],
      auctioneer: [],
      showTable: false,
      headers: [
        {
          text: "Lot No",
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
        },
        {
          text: "My Valuation",
          value: "price_valuation",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
        },
        {
          text: "Comments",
          value: "comment",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
        },
      ],
    };
  },
  methods: {
    async initialize() {
      this.auctionCenters = await masterApi.getAuctionCenter();
      this.seasons = await masterApi.getSeason();
      this.auctioneers = await api.getAuctioneer();
    },
    async filterSeasonSale() {
      if (this.auctionCenter) {
        this.overlay = true;
        this.seasonSales =
          await masterApi.getSeasonSaleBySeasonAndAuctionCenter(
            this.season,
            this.auctionCenter
          );
        this.overlay = false;
      }
    },
    async getItemNames() {
      this.item_names = await api.getItemNames(this.seasonSale);
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.item_name = [];
        } else {
          this.item_name = this.item_names.slice();
        }
      });
    },
    toggle1() {
      this.$nextTick(() => {
        if (this.selectAllAuctioneer) {
          this.auctioneer = [];
        } else {
          this.auctioneer = this.auctioneers.slice();
        }
      });
    },
    cancel() {
      this.selectedData = [];
      this.comments = [];
      this.auctioneer = [];
      this.season = null;
      this.seasonSale = null;
      this.seasonSales = [];
      this.item_names = [];
      this.item_name = [];
      this.$refs.frmFilter.resetValidation();
    },
    validate() {
      return this.$refs.frmFilter.validate();
    },
    async search() {
      if (this.validate()) {
        this.overlay = true;
        let item_names = [];
        let auctioneer = [];
        // Checking Item name -SELECT ALL-
        if (this.item_name.length == this.item_names.length) {
          this.item_name.forEach((el) => {
            item_names.push(el.item_name);
          });
        }
        // SELECT SOME
        if (
          this.item_name.length > 0 &&
          this.item_name.length != this.item_names.length
        ) {
          item_names = this.item_name;
        }

        // Checking Auctioneer -SELECT ALL-
        if (this.auctioneer.length == this.auctioneers.length) {
          this.auctioneer.forEach((el) => {
            auctioneer.push(el.id);
          });
        }
        // SELECT SOME
        if (
          this.auctioneer.length > 0 &&
          this.auctioneer.length != this.auctioneers.length
        ) {
          auctioneer = this.auctioneer;
        }

        let params = {
          auction_center: this.auctionCenter,

          season_sale: this.seasonSale,
          auctioneer: auctioneer,
          item_name: item_names,
        };
        try {
          this.comments = await api.getComments(params);
          if (this.comments.length > 0) {
            this.showTable = true;
          } else {
            this.showNoDataAlert("No Data Found");
          }
        } catch (error) {
          this.showErrorAlert(error);
        } finally {
          this.overlay = false;
          this.scrollDown();
        }
      }
    },
    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },
    download() {
      if (this.selectedData.length > 0) {
        let data = this.selectedData.map((item, i) => {
          return {
            //  id,lot_no,comment,price_valuation,item_name
            "#": i + 1,
            "Lot No": item.lot_no,
            Comment: item.comment,
            "Price Valuation": item.price_valuation,
            "Item Name": item.item_name,
          };
        });

        try {
          json2excel({
            data,
            name: "Comments",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.error("Excel export error:", e);
        }
      } else {
        this.showWarningAlert("Choose Atleast One Row");
      }
    },
    resetSeason() {
      this.season = 0;
      this.seasonSale = 0;
      this.seasonSales = [];
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    showNoDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    selectAll() {
      return this.item_name.length === this.item_names.length;
    },
    selectSome() {
      return this.item_name.length > 0 && !this.selectAll;
    },
    selectAllAuctioneer() {
      return this.auctioneer.length === this.auctioneers.length;
    },
    selectSomeAuctionner() {
      return this.auctioneer.length > 0 && !this.selectAllAuctioneer;
    },

    icon1() {
      if (this.selectAllAuctioneer) return "ni-fat-remove text-lg";
      if (this.selectSomeAuctionner) return "ni-fat-delete";
      return "fa-square-o";
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>

<style></style>
